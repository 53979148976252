.header {
  font-size: 1.1em;
  background-color: black;
  height: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 100px 1fr;
}

.header__logo {
  width: 58px;
  height: 58px;
  border-radius: 29px;
  margin-top: 1px;
}

.header__rightColumn {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 130px;
}

.header__nav-link {
  display: grid;
  height: 60px;
  justify-items: center;
  align-items: center;
}

.link__header, .link__footer {
  cursor: pointer;
}

.link__header {
  color: white;
}

.link__header:hover {
  color: #259be3;
  text-decoration: none;
}

.my-clock {
  background-color: #259be3;
  font-family: 'Lobster', cursive;
  padding-left: 22px;
  padding-top: 4px;
  box-shadow: inset 0px 1px 5px 4px rgba(0, 0, 0, 0.25);
  height: 60px;
  border-radius: 3px;
}

.pageOne__parallax {
  background-image: url(../../static/assets//images/ales-nesetril-734016-unsplash.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.pageOne__initialinfo {
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr 1fr;
}

.pageOne__initialinfo__content {
  grid-row: 1/-1;
  align-self: end;
}

.pageOne .homepage-socialmedia__nav-links {
  grid-column: 2/3;
  align-self: end;
  justify-self: end;
  font-size: 2em;
  width: 350px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.pageOne .nav-link {
  color: white;
  margin: 0px;
}

.pageOne .nav-link:hover {
  color: #259be3;
}

.pageTwo__parallax {
  background-image: url(../../static/assets/images/max-nelson-492729-unsplash.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  height: auto;
}

.pageTwo__portfolio__info {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
}

.pageTwo__title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  background-color: #259be3;
  height: 150px;
  box-shadow: inset 0px -10px 48px -10px rgba(0, 0, 0, 0.5);
}

.pageTwo__portfolio__examples {
  display: grid;
  align-content: center;
  justify-items: center;
  height: 470px;
}

.pageTwo__portfolio__examples__links {
  color: white;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
}

.pageTwo__portfolio__examples__links__h3 {
  height: 45px;
  font-size: 2.1em;
}

.pageTwo__portfolio__examples__links__paragraphs {
  font-size: 1.25rem;
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 30px;
  padding-right: 10px;
  height: 65px;
  align-self: center;
  display: flex;
}

.pageTwo__portfolio__examples__links:hover {
  color: #75d2fa;
  text-decoration: none;
}

.pageTwo__portfolio__examples__links:hover h3, .pageTwo__portfolio__examples__links:hover p {
  border-radius: 10px;
}

.pageTwo__portfolio__examples__links:hover img {
  box-shadow: 0px 22px 109px -10px black;
}

.pageTwo__portfolio__header {
  margin-bottom: 0px;
  font-size: 3.5em;
}

.site_examples {
  margin-top: 60px;
  height: 230px;
  width: 420px;
  border-radius: 20px;
}

a.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
  transition: all .5 ease;
}

.pageThree__parallax {
  background-image: url(../../static/assets/images/parallax.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-items: center;
}

.about-me {
  text-align: center;
  width: 80%;
  font-size: 1em;
  background-color: rgba(4, 105, 168, 0.85);
  border-radius: 40px;
  padding: 15px;
  margin-bottom: 110px;
}

.about-me__content {
  padding-left: 100px;
  padding-right: 100px;
}

.profile-picture {
  height: 280px;
  width: 280px;
  grid-template-rows: 1fr 1000px 1fr;
  border-radius: 200px;
  margin-top: 15px;
}

.pageFour__parallax {
  background-image: url(../../static/assets/images/max-nelson-748768-unsplash.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.pageFour__title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  background-color: #259be3;
  height: 60px;
  box-shadow: inset 0px -10px 48px -10px rgba(0, 0, 0, 0.5);
}

.pageFour__portfolio__header {
  margin-bottom: 0px;
  font-size: 1.5em;
}

.form {
  padding-top: 100px;
  display: grid;
  grid-template-rows: 80px 80px 80px 100px 150px;
  align-items: center;
  justify-items: center;
}

.form__group {
  font-size: 1.3em;
  height: 100px;
}

.fullName, .email {
  height: 50px;
  border-radius: 100px;
  padding-left: 18px;
  border-color: #259be3;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
}

.subject {
  height: 50px;
  border-radius: 100px;
  padding-left: 18px;
  border-color: #259be3;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
}

.fullName, .email, .subject, .message, .btn:focus {
  outline: none;
  width: 50vw;
}

.message {
  height: 200px;
  border-radius: 35px;
  padding-left: 36px;
  padding-top: 20px;
  font-size: 1.1em;
  border-color: #259be3;
  resize: none;
  background-color: rgba(0, 0, 0, 0.65);
  color: white;
}

.centered-btn-wrapper {
  margin-top: 125px;
}

.btn {
  color: white;
  font-size: 1.3em;
  background-color: #259be3;
  width: 150px;
  border-radius: 20px;
}

.footer {
  background-color: black;
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: 100px 1fr;
  padding: 16vh 0;
}

.footer__topRow {
  display: grid;
  margin-top: 30px;
  justify-items: center;
  align-items: center;
}

.footer__logo {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.footer__bottomRow {
  font-size: 1.1em;
  display: grid;
  margin-top: 40px;
  margin-bottom: 20px;
  grid-template-rows: repeat(5, 1fr);
  justify-items: center;
  align-items: center;
}

.footer__nav-link {
  font-size: 1.25em;
}

.link__footer {
  color: white;
}

.link__footer:hover {
  color: #259be3;
  text-decoration: none;
}

.socialmedia__nav-links {
  font-size: 2em;
  width: 350px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1300px) {
  .pageTwo__portfolio__header {
    font-size: 2em;
  }
  .pageTwo__title {
    margin-bottom: 20px;
  }
  .pageTwo__portfolio__info {
    display: flex;
    flex-direction: column;
  }
  .pageTwo__portfolio__examples {
    height: 600px;
  }
  .pageTwo__portfolio__examples__links__h3 {
    font-size: 1.7em;
  }
  .pageTwo__portfolio__examples__links__paragraphs {
    padding-bottom: 0px;
  }
  .site_examples {
    margin-top: 20px;
    height: 300px;
    width: 550px;
    border-radius: 20px;
  }
}

@media (max-width: 1100px) {
  .pageThree__parallax {
    height: auto;
  }
  .about-me {
    margin-top: 30px;
    width: 90%;
  }
  .about-me__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .form__group {
    font-size: 1em;
  }
  .fullName, .email {
    width: 80vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: #259be3;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
  .subject {
    width: 80vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: #259be3;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
  .message {
    width: 80vw;
    height: 200px;
    border-radius: 35px;
    padding-left: 24px;
    padding-top: 15px;
    font-size: 1.1em;
    border-color: #259be3;
    resize: none;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
}

@media (max-height: 610px) {
  .pageThree__parallax {
    height: auto;
  }
  .about-me {
    margin-top: 30px;
    width: 90%;
  }
  .about-me__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .pageFour__parallax {
    height: auto;
  }
  .btn {
    margin-bottom: 25px;
  }
  .footer {
    height: 100%;
  }
}

.app {
  color: white;
  font-family: 'Roboto Slab', sans-serif;
}

.nav-link {
  color: white;
  margin: 0px;
}

.nav-link:hover {
  color: #259be3;
}

.form-success-background {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success {
  color: #0dff00;
  font-size: 2.5em;
  text-align: center;
}

.error {
  color: red;
  padding-top: 10px;
}

.messages-container {
  height: auto;
  border: 3px solid #259be3;
  width: 100vw;
  display: flex;
  justify-content: space-around;
}

.messages-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #259be3;
  padding: 0px 80px;
  text-align: center;
  background-color: gray;
}

.message-type {
  color: white;
}

.message-display {
  padding-top: 10px;
  border-bottom: 2px solid #259be3;
  color: white;
}

.buttons {
  padding-top: 20px;
}

.unreadread {
  background-color: #259be3;
  color: white;
  border: none;
}

.delete-button {
  background-color: red;
  color: white;
  border: none;
}

.timestamp {
  padding-top: 10px;
}

@media (max-width: 615px) {
  .header {
    font-size: 1em;
    grid-template-columns: 80px 1fr;
  }
  .header__nav-link {
    text-align: center;
    padding-right: 15px;
  }
  .header__rightColumn {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .header__nav-clock {
    display: none;
  }
  .pageOne__parallax {
    background-image: url(../../static/assets//images/page-one-media.jpg);
  }
  .pageOne__initialinfo {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-self: center;
    text-align: center;
  }
  .pageOne__initialinfo__content {
    padding-top: 65vh;
  }
  .pageOne .homepage-socialmedia__nav-links {
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 1.7em;
    width: 275px;
  }
  .pageTwo__portfolio__header {
    font-size: 2em;
  }
  .pageTwo__title {
    margin-bottom: 20px;
  }
  .pageTwo__portfolio__info {
    display: flex;
    flex-direction: column;
  }
  .pageTwo__portfolio__examples {
    height: 410px;
    margin-bottom: 70px;
  }
  .pageTwo__portfolio__examples__links__h3 {
    font-size: 1.7em;
  }
  .pageTwo__portfolio__examples__links__paragraphs {
    padding-bottom: 100px;
  }
  .site_examples {
    height: 200px;
    width: 350px;
    margin-top: 30px;
  }
  .pageThree__parallax {
    height: auto;
  }
  .about-me {
    margin-top: 30px;
    width: 90%;
  }
  .about-me__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  .profile-picture {
    height: 200px;
    width: 190px;
    border-radius: 100px;
    margin-top: 30px;
  }
  .form__group {
    font-size: 1em;
  }
  .fullName, .email {
    width: 90vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: #259be3;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
  .subject {
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: #259be3;
    width: 90vw;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
  .message {
    width: 90vw;
    height: 200px;
    border-radius: 35px;
    padding-left: 24px;
    padding-top: 15px;
    font-size: 1.1em;
    border-color: #259be3;
    resize: none;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
  }
  .footer__nav-link {
    font-size: 1.1em;
  }
  .socialmedia__nav-links {
    font-size: 1.6em;
    width: 275px;
    display: flex;
    justify-content: space-between;
  }
}

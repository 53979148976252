.pageOne {

    &__parallax {
        background-image: url(../../static/assets//images/ales-nesetril-734016-unsplash.jpg);
        @include parallax;
    }

    &__initialinfo {
        height: 100vh;
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-columns: 1fr 1fr;

        &__content {
            grid-row: 1/-1;
            align-self: end;
        }
    }

    .homepage-socialmedia__nav-links {
        grid-column: 2/3;
        align-self: end;
        justify-self: end;
        font-size: 2em;
        width: 350px;
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
    }
    .nav-link {
        color: white;
        margin: 0px;
    }

    .nav-link:hover {
        color: $site-color-blue;
    }
    
}
.header { 
    font-size: 1.1em;
    background-color: black;
    height: 60px;
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr;

    &__logo {
        width: 58px;
        height: 58px;
        border-radius: 29px;
        margin-top: 1px;
    }

    &__rightColumn {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 130px;
    }

    &__nav-link {
        display: grid;
        height: 60px;
        justify-items: center;
        align-items: center;
    }
}

.link {
    &__header,
    &__footer {
        cursor: pointer;
    }
}

.link__header {
    color: white;
    &:hover {
        color: $site-color-blue;
        text-decoration: none;
    }
}

.my-clock {
    background-color: $site-color-blue;
    font-family: 'Lobster', cursive;
    padding-left: 22px;
    padding-top: 4px;
    box-shadow: inset 0px 1px 5px 4px rgba(0,0,0,0.25);
    height: 60px;
    border-radius: 3px;
}
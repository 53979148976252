.pageTwo {
    &__parallax {
        background-image: url(../../static/assets/images/max-nelson-492729-unsplash.jpg);
        @include parallax;
        height: auto;
    }

    &__portfolio__info {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
    }

    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        background-color: $site-color-blue;
        height: 150px;
        @include box-shadow-bottom;
    }

    &__portfolio__examples {
        display: grid;
        align-content: center;
        justify-items: center;
        height: 470px;
        
        
        &__links {
            color: white;
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            text-align: center;

            &__h3 {
                height: 45px;
                font-size: 2.1em;
            }

            &__paragraphs {
                font-size: 1.25rem;
                margin-top: 0;
                margin-bottom: 1rem;
                padding-left: 30px;
                padding-right: 10px;
                height: 65px;
                align-self: center;
                display: flex;
            }
        }
        &__links:hover {
            color: rgb(117, 210, 250);
            text-decoration: none;

            h3, p {
                border-radius: 10px;
            }

            img {
                box-shadow: 0px 22px 109px -10px rgba(0,0,0,1);
            }
        }
    }

    &__portfolio__header {
        margin-bottom: 0px;
        font-size: 3.5em;
    }
}

.site_examples {
    margin-top: 60px;
    height: 230px;
    width: 420px;
    border-radius: 20px;
}

a.anchor {
    display: block;
    position: relative;
    top: -60px;
    visibility: hidden;
    transition: all .5 ease;
}
@media (max-width: 615px) {

    .header { 
        font-size: 1em;
        grid-template-columns: 80px 1fr;

        &__nav-link {
            text-align: center;
            padding-right: 15px;
        }

        &__rightColumn {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        &__nav-clock {
            display: none;
        }
    }

    .pageOne {

        &__parallax {
            background-image: url(../../static/assets//images/page-one-media.jpg);
        }
    
        &__initialinfo {
            height: 100vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            justify-self: center;
            text-align: center;

            &__content {
                padding-top: 65vh;
            }
        }
    
        .homepage-socialmedia__nav-links {
            display: flex;
            justify-content: center;
            align-self: center;
            font-size: 1.7em;
            width: 275px;
        }
    }

    .pageTwo {

        &__portfolio__header {
            font-size: 2em;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__portfolio__info {
            display: flex;
            flex-direction: column;
        }

        &__portfolio__examples {
            height: 410px;
            margin-bottom: 70px;

            &__links {

                &__h3 {
                    font-size: 1.7em;
                }

                &__paragraphs {
                    padding-bottom: 100px;
                }
            }
        }
    }

    .site_examples {
        height: 200px;
        width: 350px;
        margin-top: 30px;
    }


    .pageThree {
        &__parallax {
            height: auto;
        }
    }

    .about-me {
        margin-top: 30px;
        width: 90%;
        &__content {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .profile-picture {
        height: 200px;
        width: 190px;
        border-radius:  100px;
        margin-top: 30px;
    }

    .form {
        &__group {
            font-size: 1em;
        }
    }

    .fullName, .email {
        @include message-form-components-mobile;
        @include form-styling;
    }
    
    .subject {
        @include message-form-components;
        width: 90vw;
        @include form-styling
    }

    .message {
        width: 90vw;
        height: 200px;
        border-radius: 35px;
        padding-left: 24px;
        padding-top: 15px;
        font-size: 1.1em;
        border-color: $site-color-blue;
        resize: none;
        @include form-styling
    }

    .footer{
        &__nav-link {
            font-size: 1.1em;
        }
    }

    .socialmedia__nav-links {
        font-size: 1.6em;
        width: 275px;
        display: flex;
        justify-content: space-between;
    }
}
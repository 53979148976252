@media (max-width: 1300px) {
    .pageTwo {

        &__portfolio__header {
            font-size: 2em;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__portfolio__info {
            display: flex;
            flex-direction: column;
        }

        &__portfolio__examples {
            height: 600px;

            &__links {

                &__h3 {
                    font-size: 1.7em;
                }

                &__paragraphs {
                    padding-bottom: 0px;
                }
            }
        }
    }
    .site_examples {
        margin-top: 20px;
        height: 300px;
        width: 550px;
        border-radius: 20px;
    }
}
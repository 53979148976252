@media (max-width: 1100px) {
    .pageThree {
        &__parallax {
            height: auto;
        }
    }

    .about-me {
        margin-top: 30px;
        width: 90%;
        &__content {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .form {
        &__group {
            font-size: 1em;
        }
    }

    .fullName, .email {
        width: 80vw;
        @include message-form-components-mobile-tablet;
        @include form-styling;
    }
    
    .subject {
        width: 80vw;
        @include message-form-components;
        @include form-styling
    }

    .message {
        width: 80vw;
        height: 200px;
        border-radius: 35px;
        padding-left: 24px;
        padding-top: 15px;
        font-size: 1.1em;
        border-color: $site-color-blue;
        resize: none;
        @include form-styling
    }
}
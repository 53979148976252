$site-color-blue: #259be3;
@mixin box-shadow-bottom { box-shadow: inset 0px -10px 48px -10px rgba(0,0,0,0.5); }
@mixin box-shadow-top { box-shadow: inset 0px 10px 48px -10px rgba(0,0,0,0.5); }
@mixin message-form-components {
    // width: 22vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: $site-color-blue;
}
@mixin form-styling {
    background-color: rgba(0,0,0, .65);
    color: white;
}
@mixin message-form-components-mobile {
    width: 90vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: $site-color-blue;
}
@mixin message-form-components-mobile-tablet {
    // width: 35vw;
    height: 50px;
    border-radius: 100px;
    padding-left: 18px;
    border-color: $site-color-blue;
}
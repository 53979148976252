.pageFour {
    &__parallax {
        background-image: url(../../static/assets/images/max-nelson-748768-unsplash.jpg);
        @include parallax;
    }
    &__title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0px;
        background-color: $site-color-blue;
        height: 60px;
        @include box-shadow-bottom;
    }

    &__portfolio__header {
        margin-bottom: 0px;
        font-size: 1.5em;
    }
}

.form {
    padding-top: 100px;
    display: grid;
    grid-template-rows: 80px 80px 80px 100px 150px;
    align-items: center;
    justify-items: center;
    
    &__group {
        font-size: 1.3em;
        height: 100px;
    }
}

.fullName, .email {
    @include message-form-components;
    @include form-styling;
}

.subject {
    @include message-form-components;
    // width: 37vw;
    @include form-styling
}

.fullName, .email, .subject, .message, .btn:focus {
    outline: none;
    width: 50vw;
}

.message {
    // width: 70vw;
    height: 200px;
    border-radius: 35px;
    padding-left: 36px;
    padding-top: 20px;
    font-size: 1.1em;
    border-color: $site-color-blue;
    resize: none;
    @include form-styling
}

.centered-btn-wrapper {
    margin-top: 125px;
}

.btn {
    color: white;
    font-size: 1.3em;
    background-color: $site-color-blue;
    width: 150px;
    border-radius: 20px;
}
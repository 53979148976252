.pageThree {
    &__parallax {
        background-image: url(../../static/assets/images/parallax.jpg);
        @include parallax;
        display: grid;
        align-items: center;
        justify-items: center;
    }
}

.about-me {
    text-align: center;
    width: 80%;
    font-size: 1em;
    background-color: rgba(4, 105, 168, 0.85);
    border-radius: 40px;
    padding: 15px;
    margin-bottom: 110px;
    &__content {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.profile-picture {
    height: 280px;
    width: 280px;
    grid-template-rows: 1fr 1000px 1fr;
    border-radius:  200px;
    margin-top: 15px;
}
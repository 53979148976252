@media (max-height: 610px) {
    .pageThree {
        &__parallax {
            height: auto;
        }
    }

    .about-me {
        margin-top: 30px;
        width: 90%;
        &__content {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
    
    .pageFour {
        &__parallax {
            height: auto;
        }
    }

    .btn {
        margin-bottom: 25px;
    }

    .footer{
        height: 100%;
    }
}
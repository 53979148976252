.footer{
    background-color: black;
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: 100px 1fr;
    padding: 16vh 0;

    &__topRow {
        display: grid;
        margin-top: 30px;
        justify-items: center;
        align-items: center;
    }

    &__logo {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    &__bottomRow {
        font-size: 1.1em;
        display: grid;
        margin-top: 40px;
        margin-bottom: 20px;
        grid-template-rows: repeat(5, 1fr);
        justify-items: center;
        align-items: center;
    }

    &__nav-link {
        font-size: 1.25em;
    }
}

.link__footer {
    color: white;
    &:hover {
        color: $site-color-blue;
        text-decoration: none;
    }
}

.socialmedia__nav-links {
    font-size: 2em;
    width: 350px;
    display: flex;
    justify-content: space-between;
}